import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faMapMarkerAlt,
  faCalendarAlt,
  faChevronDown,
  faFile,
  faLink
} from '@fortawesome/free-solid-svg-icons'

library.add(fab, faMapMarkerAlt, faCalendarAlt, faChevronDown, faLink, faFile)


// FaFacebookF, FaYoutube, FaLinkedinIn, FaTwitter, FaInstagram, FaTiktok