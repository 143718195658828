import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import globalConfig from '../utils/querys/globalConfig'

function Seo({ description, lang, meta, title, keywords }) {
  const data = globalConfig()
  let siteDesc= 'Sitio web UCC'
  let siteTitle = 'Universidad Católica de Córdoba'
  let siteKeywords = 'Universidad Católica de Córdoba, UCC'
  const {
    author,
    robots,
    pageMetadata
  } = data?.allStrapiGlobalConfig?.nodes[0]

  if(pageMetadata !== null){
    siteDesc = pageMetadata.siteDesc
    siteKeywords = pageMetadata.pageKeywords
    siteTitle  = pageMetadata.siteTitle
  }
  

  const metaDescription = description ? description : siteDesc
  const defaultTitle = title ? title : siteTitle
  const preventIndex = robots ? 'index, follow' : 'noindex, nofollow'
  const defaultKeywords = keywords ? keywords : siteKeywords

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle}
      titleTemplate={defaultTitle ? `${defaultTitle} | UCC` : null}
      meta={[
        {
          name: 'robots',
          content: preventIndex,
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: defaultKeywords,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: author || '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
      // link={[]}
    />
  )
}

Seo.defaultProps = {
  lang: 'es',
  meta: [],
  description: '',
}

Seo.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default Seo