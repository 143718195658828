import { graphql, useStaticQuery } from 'gatsby'

const globalConfig = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiGlobalConfig {
        nodes {
          author
          robots
          pageMetadata {
            siteDesc: pageDescription
            siteKeywords: pageKeywords
            siteTitle: pageTitle
          }
          siteURL
        }
      }
    }
  `)
  return query
}
export default globalConfig
