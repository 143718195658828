import React, { createContext, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'

const themes = {
  light: {
    '--nav-primary': '#666666',
    '--nav-container': '#ffffff',
    '--nav-active': '#191919',
    '--nav-menu': '#fff',

    '--form-field-background': '#0e192a',

    '--body-title': '#182c49',

    '--primary': '#555',
    '--primary-invert': '#ffffff',
    '--primary-info': '#1d317d',
    '--primary-grey': '#666666',
    '--primary-container': '#ffffff',
    '--primary-container-dark': '#182c49',
    '--primary-container-lightblue': '#009abb',

    '--course-container': '#f8f9fa',

    '--cards-container': '#ffffff',
    '--cards-container-secondary': '#f7f7f7',

    '--secondary': '#182c49',
    '--secondary-container': '#182c49',
    '--terciary-container': '#f8f9fa',
  },
  dark: {
    '--nav-primary': '#cecece',
    '--nav-container': '#383838',
    '--nav-active': '#e7e7e7',
    '--nav-menu': 'rgba(255, 255, 255, 0.95)',

    '--form-field-background': '#292929',

    '--body-title': '#ffffff',

    '--primary': '#ffffff',
    '--primary-invert': '#555',
    '--primary-info': '#0063aa',
    '--primary-grey': '#ffffff',
    '--primary-container': '#292929',
    '--primary-container-dark': '#ffffff14',

    '--course-container': '#292929',

    '--cards-container': '#373737',
    '--cards-container-secondary': '#ffffff14',

    '--secondary': '#ffffff',
    '--secondary-container': '#191919',
    '--terciary-container': '#191919',
  }
}

const ThemeContext = createContext(null)

// const { theme, setTheme, toggleTheme } = useTheme()
export const useTheme = () => useContext(ThemeContext)

// cambiar si cambia el tema por defecto
const DEFAULT_THEME = 'light'

const ThemeProvider = ({ children }) => {
  const localTheme =
    typeof window !== 'undefined' ? localStorage.getItem('theme') : undefined
  const deviseTheme = getDeviseTheme()
  const [theme, setTheme] = useState(null)

  // Guarda el estado del Tema del Sitio
  useEffect(() => {
    setTheme(localTheme || deviseTheme || DEFAULT_THEME)
  }, [])
  // Guarda el estado del Tema del Sitio
  useEffect(() => {
    localStorage.setItem('theme', theme)
  }, [theme])

  // Cambiando de Tema con el boton del sitio
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  // Cambio a "light" al recargar el sitio
  const reload =
    typeof window !== 'undefined'
      ? (window.onbeforeunload = function () {
          localStorage.setItem('theme', 'light')
        })
      : undefined
  reload // Llamando a la funcion

  return (
    <ThemeContext.Provider value={{ theme, setTheme, toggleTheme }}>
      <div style={{ ...themes[theme], background: 'var(--primary-container)' }}>
        {children}
      </div>
    </ThemeContext.Provider>
  )
}

const getDeviseTheme = () => {
  if (typeof window !== 'undefined') {
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark'
      } else {
        return 'light'
      }
    } else {
      return DEFAULT_THEME
    }
  } else {
    return undefined
  }
}
ThemeProvider.propTypes = {
  children: PropTypes.object
}

export default ThemeProvider
